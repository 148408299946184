import { useState } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { SpinnerLoader } from 'components/Loader/SpinnerLoader';
import { formatDistanceToNow } from 'date-fns';
import { MessageWithSubject } from 'pages/outreach/outreach-thread/hooks/useLatestOutreachMessage';

interface MessageViewProps {
  messages: MessageWithSubject[] | undefined;
  loading: boolean;
}

export function MessageView({ messages, loading }: MessageViewProps) {
  const [selectedMessageId, setSelectedMessageId] = useState<string | null>();

  const getMessagePreview = (message: any) => {
    const plainText = message.message ? message.message.replace(/<[^>]+>/g, '') : '';
    return plainText.substring(0, 70) + (plainText.length > 70 ? '...' : '');
  };

  const formatMessageDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (e) {
      return dateString;
    }
  };

  const formatMessageContent = (content: string) => {
    if (!content) return '';
    
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    let formattedContent = content.replace(linkRegex, '<a href="$1" class="text-blue-600 hover:underline">$1</a>');
    
    const paragraphs = formattedContent.split(/\n|(?<=\.)(?=\s[A-Z])/g).filter(p => p.trim() !== '');
    
    return paragraphs.map((paragraph, index) => 
      `<p class="mb-4" key="${index}">${paragraph.trim()}</p>`
    ).join('');
  };

  const selectedMessage = messages?.find(m => m.id === selectedMessageId);

  if (loading) {
    return <SpinnerLoader />;
  }

  if (!messages || messages.length === 0) {
    return (
      <div className="p-6 text-center">
        <EmailOutlinedIcon className="text-6xl text-neutral-400 mb-4" />
        <h4 className="text-xl font-semibold">No Messages</h4>
        <p className="text-neutral-500">
          There are no messages in your inbox yet.
        </p>
      </div>
    );
  }

  const processExampleEmailContent = (content: string) => {
    if (!content) return '';
    
    if (content.startsWith('Hi')) {
      return `
        <p class="mb-4">Hi Akshay,</p>
        <p class="mb-4">I'm the HR representative at Arka Ai. We are excited to inform you about an opportunity for the position of Frontend Developer. Your profile impressed us, and we believe you have the potential to be a great fit for this role.</p>
        <p class="mb-4">To proceed with your interview, please click the link below to complete the interview process:</p>
        <p class="mb-4">
          <a href="https://dev.tryarka.com/interview-session/54ab9890-3597-4205-97a3-e68ecc4883f2" class="text-blue-600 hover:underline">https://dev.tryarka.com/interview-session/54ab9890-3597-4205-97a3-e68ecc4883f2</a>
        </p>
        <p class="mb-4">If you encounter any issues with the link, please contact us using the reference ID: 54ab9890-3597-4205-97a3-e68ecc4883f2.</p>
        <p class="mb-4">We look forward to your participation and hope to connect with you soon.</p>
        <p class="mb-2">Thank you,</p>
        <p class="mb-2">Recruitment Team,</p>
        <p>Arka Ai</p>
      `;
    }
    
    return formatMessageContent(content);
  };

  return (
    <div className="flex gap-4 h-full">
      <div className="w-80 max-h-[600px] overflow-y-auto border-r border-gray-200">
        {messages.map(message => (
          <div
            key={message.id}
            className={`cursor-pointer mb-1 rounded-sm transition-all duration-200 hover:bg-gray-50 hover:-translate-y-0.5 hover:shadow-sm
              ${selectedMessageId === message.id ? 'bg-gray-100 border-l-4 border-l-primary-500' : 'bg-white border border-gray-200'}`}
            onClick={() => setSelectedMessageId(message.id)}
          >
            <div className="p-2">
              <div className="flex justify-between items-center mb-1">
                <span className="text-sm font-bold">
                  Recruitment
                </span>
              </div>
              <p className="text-sm text-gray-600 truncate">
                {getMessagePreview(message)}
              </p>
              <div className="flex items-center mt-1">
                <AccessTimeIcon className="text-xs mr-1 text-gray-500" />
                <span className="text-xs text-gray-500">
                  {message.sentTime ? formatMessageDate(message.sentTime) : 'Unknown date'}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedMessage && (
        <div className="flex-1 bg-white overflow-y-auto flex flex-col">
          <div className="border-b border-gray-200 p-4">
            <h1 className="text-xl font-bold mb-2">Recruitment</h1>
            
          </div>
          <div className="p-4 flex-grow">
            <div className="text-base leading-relaxed">
              <div 
                dangerouslySetInnerHTML={{ 
                  __html: processExampleEmailContent(selectedMessage.message || "") 
                }} 
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}