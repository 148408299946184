import { Box, Button, FormControl, FormHelperText, Typography } from '@mui/joy';
import { InterviewConfig } from 'api-types';
import { Input } from 'components/styled/Input';
import { Textarea } from 'components/styled/TextArea';
import { useState } from 'react';
import { object, string, array } from 'yup';
import { AutoCompleteFetcher } from 'components/autocomplete/AutoCompleteFetcher';
import { fetchJobTitles } from 'api/api';
import { useAppStore } from 'store/appStore';

const nameSchema = string()
  .required('Name is required')
  .min(3, 'Name must be at least 3 characters')
  .max(50, 'Name must be at most 50 characters');

const skillsSchema = array()
  .of(string().trim())
  .required('Skills are required')
  .min(1, 'At least one skill is required');

export const roleSchema = string().required('Role is required');
export const focusSchema = string().required('Focus is required');

export const metaInfoSchema = object({
  name: nameSchema,
});

export interface InterviewStageEditProps {
  interviewConfig: InterviewConfig | undefined;
  submitting: boolean;
  handleChange: (interviewConfig: InterviewConfig) => void;
  handleBack: () => void;
  handleNext: () => void;
}

export function InterviewMetaInfoEdit({
  interviewConfig,
  handleChange,
  handleBack,
  handleNext,
  submitting,
}: InterviewStageEditProps) {
  const [nameError, setNameError] = useState<string | undefined>();
  const [roleError, setRoleError] = useState<string | undefined>();
  const [focusError, setFocusError] = useState<string | undefined>();
  const [skillsError, setSkillsError] = useState<string | undefined>();
  const [autoGenError, setAutoGenError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchScreeningCriteriaAndQuestions = useAppStore(
    state => state.fetchScreeningCriteriaAndQuestions
  );

  const onNameChange = (value: string) => {
    handleChange({
      ...interviewConfig,
      metaInfo: { ...interviewConfig?.metaInfo, name: value },
    });
    validateName(value);
  };

  const onDescriptionChange = (value: string) => {
    handleChange({
      ...interviewConfig,
      metaInfo: { ...interviewConfig?.metaInfo, description: value },
    });
  };

  const validateName = (name: string | undefined) => {
    try {
      nameSchema.validateSync(name);
      setNameError(undefined);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setNameError(error.message);
      }
      return false;
    }
  };

  const validateRole = (role: string | undefined) => {
    try {
      roleSchema.validateSync(role);
      setRoleError(undefined);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setRoleError(error.message);
      }
      return false;
    }
  };

  const onRoleChange = (value: string) => {
    handleChange({
      ...interviewConfig,
      role: value,
    });
    validateRole(value);
  };

  const validateFocus = (focus: string | undefined) => {
    try {
      focusSchema.validateSync(focus);
      setFocusError(undefined);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setFocusError(error.message);
      }
      return false;
    }
  };

  const onFocusChange = (value: string) => {
    handleChange({
      ...interviewConfig,
      focus: value,
    });
    validateFocus(value);
  };

  const validateSkills = (skills: string[] | undefined) => {
    try {
      skillsSchema.validateSync(skills);
      setSkillsError(undefined);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setSkillsError(error.message);
      }
      return false;
    }
  };

  const onSkillsChange = (value: string) => {
    const skillsArray = value.split(',').map(skill => skill.trim());

    handleChange({
      ...interviewConfig,
      skills: skillsArray,
    });

    if (value[value.length - 1] !== ',') {
      validateSkills(skillsArray.filter(Boolean));
    }
  };

  const validate = () => {
    const nameValid = validateName(interviewConfig?.metaInfo?.name);
    const roleValid = validateRole(interviewConfig?.role);
    const skillsValid = validateSkills(interviewConfig?.skills);
    const focusValid = validateFocus(interviewConfig?.focus);
    return nameValid && roleValid && skillsValid && focusValid;
  };

  const handleNextWithoutAuto = () => {
    if (validate()) {
      handleNext();
    }
  };

  const handleAutoGenerate = async () => {
    if (validate()) {
      setAutoGenError(undefined);
      setIsLoading(true);

      try {
        const response = await fetchScreeningCriteriaAndQuestions({
          role: interviewConfig?.role,
          skills: interviewConfig?.skills,
          description: interviewConfig?.metaInfo?.description,
        });
        handleChange({
          ...interviewConfig,
          questions: response.questions?.map(q => ({ question: q })),
          screeningCriteria: { criteria: response.screeningCriteria },
        });

        handleNext();
      } catch (error) {
        console.error('Error fetching screening criteria and questions:', error);
        setAutoGenError('Failed to auto generate interview questions. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 260px)',
          flexGrow: 1,
        }}
      >
        <FormControl
          sx={{ marginBottom: '10px', maxWidth: '500px', width: '100%' }}
          error={!!nameError}
        >
          <Typography level="title-sm" gutterBottom>
            Company Name
          </Typography>
          <Input
            name="metaInfo.name"
            id="metaInfo.name"
            value={interviewConfig?.metaInfo?.name}
            onChange={e => onNameChange(e.target.value)}
            placeholder="Company name"
            sx={{ fontWeight: '500' }}
            size="sm"
          />
          <FormHelperText>{nameError}</FormHelperText>
        </FormControl>

        <Box sx={{ marginBottom: '10px', maxWidth: '500px', width: '100%' }}>
          <AutoCompleteFetcher<string>
            label="Role"
            selectedItems={interviewConfig?.role || ''}
            onSelectionChange={value => onRoleChange(value as string)}
            fetchItems={async (searchQuery: string) => {
              const { values } = await fetchJobTitles({ prefix: searchQuery });
              return values?.map(value => value.value || '') || [];
            }}
            getOptionLabel={option => option || ''}
            freeSolo
            multiple={false}
            constructOptionFromInputValue={inputValue => inputValue}
            error={roleError}
          />
        </Box>

        <FormControl
          sx={{ marginBottom: '10px', maxWidth: '500px', width: '100%' }}
          error={!!skillsError}
        >
          <Typography level="title-sm" gutterBottom>
            Skills
          </Typography>
          <Input
            name="skills"
            id="skills"
            value={interviewConfig?.skills?.join(', ') || ''}
            onChange={e => onSkillsChange(e.target.value)}
            placeholder="Enter skills separated by commas"
            sx={{ fontWeight: '500' }}
            size="sm"
          />
          <FormHelperText>{skillsError}</FormHelperText>
        </FormControl>

        <FormControl
          sx={{ marginBottom: '5px', maxWidth: '500px', width: '100%' }}
          error={!!focusError}
        >
          <Typography level="title-sm" gutterBottom>
            Focus
          </Typography>
          <Textarea
            name="focus"
            id="focus"
            value={interviewConfig?.focus}
            onChange={e => onFocusChange(e.target.value)}
            size="sm"
            minRows={3}
            sx={{ fontWeight: '500' }}
          />
          <FormHelperText>{focusError}</FormHelperText>
          <div>
            <Typography level="body-sm" gutterBottom>
              This will be used by our AI agent to conduct the interview with a particular focus.
              Examples:
            </Typography>
            <Typography level="body-sm" gutterBottom>
              - Behavioral interview to understand the candidate's fit for the role.
            </Typography>
            <Typography level="body-sm" gutterBottom>
              - Technical interview to understand the candidate's technical skills.
            </Typography>
          </div>
          {autoGenError && (
            <Typography level="body-xs" color="danger">
              {autoGenError}
            </Typography>
          )}
        </FormControl>

        <FormControl sx={{ marginBottom: '5px', maxWidth: '500px', width: '100%' }}>
          <Typography level="title-sm" gutterBottom>
            Job Description (Optional)
          </Typography>
          <Textarea
            name="metaInfo.description"
            id="metaInfo.description"
            value={interviewConfig?.metaInfo?.description}
            onChange={e => onDescriptionChange(e.target.value)}
            size="sm"
            minRows={3}
            sx={{ fontWeight: '500' }}
            placeholder="Enter the Job Description"
          />
        </FormControl>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Button variant="plain" color="neutral" onClick={() => handleBack()}>
          Back
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
          <Button onClick={handleNextWithoutAuto} disabled={submitting}>
            Next
          </Button>
          <Button
            onClick={handleAutoGenerate}
            disabled={submitting || isLoading}
            loading={isLoading}
            variant="solid"
            color="primary"
          >
            Auto Generate
          </Button>
        </Box>
      </Box>
    </>
  );
}
