import { CheckCircle, AlertCircle, XCircle, UserCheck, ThumbsUp, AlertTriangle, ThumbsDown, HelpCircle } from 'lucide-react';

export const formatTimestamp = (timestamp: string | undefined): string => {
  if (!timestamp) return '';
  const date = new Date(timestamp);
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

export const getScoreColor = (score: number): string => {
  if (score >= 3) return 'text-green-600';
  if (score >= 1) return 'text-yellow-600';
  return 'text-red-600';
};

export const getOverallScoreColor = (score: number): string => {
  if (score >= 75) return 'text-green-800';
  if (score >= 50) return 'text-yellow-800';
  return 'text-red-800';
};

export const getScoreEmoji = (score: number): JSX.Element => {
  if (score >= 3) return <CheckCircle className="h-5 w-5 text-green-600" />;
  if (score >= 1) return <AlertCircle className="h-5 w-5 text-yellow-600" />;
  return <XCircle className="h-5 w-5 text-red-600" />;
};

export const getHireableInfo = (hireable: string) => {
  switch (hireable) {
    case 'Strong Hire':
      return {
        icon: <ThumbsUp className="mr-1 h-4 w-4" />,
        text: 'Strong Hire',
        className: 'bg-green-100 text-green-800 border-green-200',
      };
    case 'Hire':
      return {
        icon: <UserCheck className="mr-1 h-4 w-4" />,
        text: 'Hire',
        className: 'bg-blue-100 text-blue-800 border-blue-200',
      };
    case 'Lean Hire':
      return {
        icon: <ThumbsUp className="mr-1 h-4 w-4" />,
        text: 'Lean Hire',
        className: 'bg-cyan-100 text-cyan-800 border-cyan-200',
      };
    case 'Lean No Hire':
      return {
        icon: <ThumbsDown className="mr-1 h-4 w-4" />,
        text: 'Lean No Hire',
        className: 'bg-amber-100 text-amber-800 border-amber-200',
      };
    case 'No Hire':
      return {
        icon: <AlertTriangle className="mr-1 h-4 w-4" />,
        text: 'No Hire',
        className: 'bg-red-100 text-red-800 border-red-200',
      };
    default:
      return {
        icon: <HelpCircle className="mr-1 h-4 w-4" />,
        text: hireable || 'Unknown',
        className: 'bg-gray-100 text-gray-800 border-gray-300',
      };
  }
};
