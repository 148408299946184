import { MediaDeviceMenu, TrackToggle } from '@livekit/components-react';

import { ParticipantPlaceholder, usePersistentUserChoices } from '@livekit/components-react';

import { LocalUserChoices } from '@livekit/components-react';
import React, { useCallback, useState } from 'react';
import {
  createLocalTracks,
  CreateLocalTracksOptions,
  LocalTrack,
  LocalVideoTrack,
  Mutex,
} from 'livekit-client';
import { facingModeFromLocalTrack, LocalAudioTrack, Track } from 'livekit-client';
import { log } from '@livekit/components-core';
import { Box, Typography } from '@mui/joy';
import { roomOptionsStringifyReplacer } from './lib/utils';

export interface UserChoices {
  firstName?: string;
  lastName?: string;
  email?: string;
  audioEnabled?: boolean;
  videoEnabled?: boolean;
  audioDeviceId?: string;
  videoDeviceId?: string;
}

interface PreJoinProps {
  defaults: UserChoices;
  onSubmit: (values: UserChoices) => void;
}

export function PreJoin({ defaults = {}, onSubmit }: PreJoinProps) {
  const [userChoices, setUserChoices] = useState<UserChoices>(defaults);

  const partialDefaults: Partial<LocalUserChoices> = {
    ...(defaults.audioDeviceId !== undefined && { audioDeviceId: defaults.audioDeviceId }),
    ...(defaults.videoDeviceId !== undefined && { videoDeviceId: defaults.videoDeviceId }),
    ...(defaults.audioEnabled !== undefined && { audioEnabled: defaults.audioEnabled }),
    ...(defaults.videoEnabled !== undefined && { videoEnabled: defaults.videoEnabled }),
    ...(defaults.firstName !== undefined && {
      username: getUserName(defaults.firstName, defaults.lastName),
    }),
  };

  const {
    userChoices: initialUserChoices,
    saveAudioInputDeviceId,
    saveAudioInputEnabled,
    saveVideoInputDeviceId,
    saveVideoInputEnabled,
    saveUsername,
  } = usePersistentUserChoices({
    defaults: partialDefaults,
    preventSave: false,
    preventLoad: false,
  });

  // Initialize device settings
  const [audioEnabled, setAudioEnabled] = React.useState<boolean>(initialUserChoices.audioEnabled);
  const [videoEnabled, setVideoEnabled] = React.useState<boolean>(initialUserChoices.videoEnabled);
  const [audioDeviceId, setAudioDeviceId] = React.useState<string>(
    initialUserChoices.audioDeviceId
  );
  const [videoDeviceId, setVideoDeviceId] = React.useState<string>(
    initialUserChoices.videoDeviceId
  );

  const [email, setEmail] = React.useState(defaults.email);
  const [emailError, setEmailError] = React.useState<string | undefined>(undefined);
  const [lastName, setLastName] = React.useState(defaults.lastName);
  const [firstName, setFirstName] = React.useState(defaults.firstName);

  const [isSilent, setIsSilent] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  // Save user choices to persistent storage.
  React.useEffect(() => {
    saveAudioInputEnabled(audioEnabled);
  }, [audioEnabled, saveAudioInputEnabled]);
  React.useEffect(() => {
    saveVideoInputEnabled(videoEnabled);
  }, [videoEnabled, saveVideoInputEnabled]);
  React.useEffect(() => {
    saveAudioInputDeviceId(audioDeviceId);
  }, [audioDeviceId, saveAudioInputDeviceId]);
  React.useEffect(() => {
    saveVideoInputDeviceId(videoDeviceId);
  }, [videoDeviceId, saveVideoInputDeviceId]);

  React.useEffect(() => {
    saveUsername(getUserName(firstName, lastName));
  }, [getUserName, saveUsername, firstName, lastName]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    if (validateEmail(email)) {
      setEmail(email);
      setEmailError(undefined);
      setIsValid(isPreJoinValid(userChoices));
    } else {
      setEmailError('Invalid email');
      setIsValid(false);
    }
  };

  const handleError = useCallback((error: Error | undefined) => {
    if (error) {
      console.error('bhenchod', error);
      setError(
        'There was an error, please make sure correct permissions are granted to enable audio and video.'
      );
      setIsValid(false);
    } else {
      setError(undefined);
    }
  }, []);

  const tracks = usePreviewTracks(
    {
      audio: audioEnabled ? { deviceId: initialUserChoices.audioDeviceId } : false,
      video: videoEnabled ? { deviceId: initialUserChoices.videoDeviceId } : false,
    },
    handleError
  );

  const videoEl = React.useRef(null);

  const videoTrack = React.useMemo(
    () => tracks?.filter(track => track.kind === Track.Kind.Video)[0] as LocalVideoTrack,
    [tracks]
  );

  const facingMode = React.useMemo(() => {
    if (videoTrack) {
      const { facingMode } = facingModeFromLocalTrack(videoTrack);
      return facingMode;
    } else {
      return 'undefined';
    }
  }, [videoTrack]);

  const audioTrack = React.useMemo(
    () => tracks?.filter(track => track.kind === Track.Kind.Audio)[0] as LocalAudioTrack,
    [tracks]
  );

  React.useEffect(() => {
    if (videoEl.current && videoTrack) {
      videoTrack.unmute();
      videoTrack.attach(videoEl.current);
    }

    return () => {
      videoTrack?.detach();
    };
  }, [videoTrack]);

  const isPreJoinValid = (values: UserChoices) => {
    return (
      values.firstName !== '' && values.email !== '' && values.audioEnabled && values.videoEnabled
    );
  };

  const [isValid, setIsValid] = React.useState<boolean>();

  React.useEffect(() => {
    const newUserChoices = {
      firstName,
      lastName,
      email,
      videoEnabled,
      videoDeviceId,
      audioEnabled,
      audioDeviceId,
    };
    setUserChoices(newUserChoices);
    setIsValid(isPreJoinValid(newUserChoices));
  }, [firstName, lastName, email, videoEnabled, audioEnabled, audioDeviceId, videoDeviceId]);

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (isPreJoinValid(userChoices)) {
      if (typeof onSubmit === 'function') {
        onSubmit(userChoices);
      }
    } else {
      log.warn('Validation failed with: ', userChoices);
    }
  }

  React.useEffect(() => {
    if (audioTrack) {
      audioTrack.checkForSilence().then(isSilent => {
        console.log('isSilent', isSilent);
        setIsSilent(isSilent);
        if (isSilent) {
          setIsValid(false);
        } else {
          setIsValid(isPreJoinValid(userChoices));
        }
      });
    }
  }, [audioTrack, userChoices]);

  return (
    <div className="lk-prejoin">
      <div className="lk-video-container">
        {videoTrack && (
          <video ref={videoEl} width="1280" height="720" data-lk-facing-mode={facingMode} />
        )}
        {(!videoTrack || !videoEnabled) && (
          <div className="lk-camera-off-note">
            <ParticipantPlaceholder />
          </div>
        )}
      </div>
      <div className="lk-button-group-container">
        <div className="lk-button-group audio">
          <TrackToggle
            initialState={audioEnabled}
            source={Track.Source.Microphone}
            onChange={enabled => setAudioEnabled(enabled)}
          >
            Microphone
          </TrackToggle>
          <div className="lk-button-group-menu">
            <MediaDeviceMenu
              initialSelection={audioDeviceId}
              kind="audioinput"
              disabled={!audioTrack}
              tracks={{ audioinput: audioTrack }}
              onActiveDeviceChange={(_, id) => setAudioDeviceId(id)}
            />
          </div>
        </div>

        <div className="lk-button-group video">
          <TrackToggle
            initialState={videoEnabled}
            source={Track.Source.Camera}
            onChange={enabled => setVideoEnabled(enabled)}
          >
            Camera
          </TrackToggle>
          <div className="lk-button-group-menu">
            <MediaDeviceMenu
              initialSelection={videoDeviceId}
              kind="videoinput"
              disabled={!videoTrack}
              tracks={{ videoinput: videoTrack }}
              onActiveDeviceChange={(_, id) => setVideoDeviceId(id)}
            />
          </div>
        </div>
      </div>
      <Typography level="body-sm" sx={{ color: '#dc5858' }}>
        {isSilent
          ? 'Unable to detect audio, try to speak or try changing the microphone device'
          : ''}
      </Typography>
      {error && (
        <Typography level="body-sm" sx={{ color: '#dc5858' }}>
          {error}
        </Typography>
      )}

      <form className="lk-username-container my-4">
        <input
          className="lk-form-control"
          id="firstName"
          name="firstName"
          type="text"
          defaultValue={firstName}
          placeholder="Enter your first name"
          onChange={inputEl => setFirstName(inputEl.target.value)}
          autoComplete="off"
        />
        <input
          className="lk-form-control"
          id="lastName"
          name="lastName"
          type="text"
          defaultValue={lastName}
          placeholder="Enter your last name"
          onChange={inputEl => setLastName(inputEl.target.value)}
          autoComplete="off"
        />
        <input
          className="lk-form-control"
          id="email"
          name="email"
          type="email"
          defaultValue={email}
          placeholder="Enter your email"
          onChange={handleEmailChange}
          autoComplete="off"
        />
        {emailError && (
          <Typography level="body-sm" sx={{ color: '#dc5858' }}>
            {emailError}
          </Typography>
        )}
        <div className="m-auto">
          <button
            className="lk-button lk-join-button"
            type="submit"
            onClick={handleSubmit}
            disabled={!isValid}
          >
            Join Room
          </button>
        </div>
      </form>
    </div>
  );
}

export function usePreviewTracks(
  options: CreateLocalTracksOptions,
  handleError: (err: Error | undefined) => void
) {
  const [tracks, setTracks] = React.useState<LocalTrack[]>();

  const trackLock = React.useMemo(() => new Mutex(), []);

  React.useEffect(() => {
    let needsCleanup = false;
    let localTracks: Array<LocalTrack> = [];
    trackLock.lock().then(async unlock => {
      try {
        if (options.audio || options.video) {
          localTracks = await createLocalTracks(options);

          if (needsCleanup) {
            localTracks.forEach(tr => tr.stop());
          } else {
            setTracks(localTracks);
          }
          handleError(undefined);
        }
      } catch (e: unknown) {
        if (handleError && e instanceof Error) {
          handleError(e);
        } else {
          log.error(e);
        }
      } finally {
        unlock();
      }
    });

    return () => {
      needsCleanup = true;
      localTracks.forEach(track => {
        track.stop();
      });
    };
  }, [JSON.stringify(options, roomOptionsStringifyReplacer), handleError, trackLock]);

  return tracks;
}

export function getUserName(firstName?: string, lastName?: string) {
  return `${firstName} ${lastName}`.trim();
}
