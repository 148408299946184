import { Button, Checkbox, Table } from '@mui/joy';
import {
  Candidate,
  CandidateOutreachStats,
  CandidatePipeline,
  Outreach,
  OutreachThread,
  PipelineStageEnum,
} from 'api-types';
import { Chip } from '../../../../../components/Chip';
import {
  ColumnFiltersState,
  OnChangeFn,
  RowSelectionState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { PipelineCreateFilterButton, getUnsetFilters } from '../filter/PipelineCreateFilterButton';
import { RepliedChip, RepliedEnum, StageChip, StatusChip, StatusEnum } from '../PipelineChips';
import styles from '.././pipeline-table.module.css';
import { getCandidateOutreachStage } from '../../utils';
import {
  hasEmailReplyOnOutreachThread,
  hasPhoneConversationOnOutreachThread,
  isOutreachThreadFinished,
} from '../../../utils';
import { FilterConfig, RepliedFilter, StageFilter, StatusFilter } from '../filter/FilterComponents';
import { PipelineFilterButton } from '../filter/PipelineFilterButton';
import { PIPELINE_STAGE_TO_USER_STRINGS } from '../../constants';
import { getCandidateRowById, getCandidateStage } from './utils';
import { useFeatureParams } from '../../../../../common/useFeatureParam';

export interface CandidatePipelineTableRow {
  candidate: Candidate;
  stage?: PipelineStageEnum;
  stat?: CandidateOutreachStats;
  candidateOutreachThread?: OutreachThread;
}

const columnHelper = createColumnHelper<CandidatePipelineTableRow>();

interface CandidatePipelineTableProps {
  candidatePipeline?: CandidatePipeline;
  candidateOutreachStats?: CandidateOutreachStats[];
  candidateOutreachThreads?: OutreachThread[];
  additionalCandidates?: Candidate[];
  outreach?: Outreach;
  onCandidateProfileOpen?: (candidateId: string) => void;
  tableConfig?: TableConfig;
}

interface TableConfig {
  onCandidateSelection?: (selectedCandidateIds: string[]) => void;
}

export function CandidatePipelineTable({
  candidatePipeline,
  candidateOutreachStats,
  candidateOutreachThreads,
  outreach,
  additionalCandidates,
  onCandidateProfileOpen,
  tableConfig,
}: CandidatePipelineTableProps) {
  const [columnsFiltersState, setColumnsFiltersState] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const getStatus = (stage: PipelineStageEnum | string, candidate: Candidate) => {
    if (stage === PipelineStageEnum.Shortlist) {
      return StatusEnum.NotInitialized;
    }
    const outreachThread = candidateOutreachThreads?.find(
      thread => thread.candidateId === candidate.id
    );
    if (outreachThread) {
      if (isOutreachThreadFinished(outreachThread, outreach)) {
        return StatusEnum.Finished;
      }
      return StatusEnum.InProcess;
    }

    return StatusEnum.NotInitialized;
  };

  const getReplied = (candidateOutreachThread?: OutreachThread) => {
    if (!candidateOutreachThread) {
      return RepliedEnum.NotReplied;
    }
    if (hasPhoneConversationOnOutreachThread(candidateOutreachThread)) {
      return RepliedEnum.Interested;
    }
    if (hasEmailReplyOnOutreachThread(candidateOutreachThread)) {
      return RepliedEnum.No;
    }
    return RepliedEnum.NotReplied;
  };

  const columnConfigs = useMemo(() => {
    const colConfigs: any = [
      columnHelper.accessor(row => `${row.candidate.firstName} ${row.candidate.lastName || ''}`, {
        id: 'candidateName',
        cell: info => (
          <Button
            variant="plain"
            color="neutral"
            size="sm"
            sx={{
              backgroundColor: 'transparent !important',
              justifyContent: 'center',
              padding: '4px 8px',
              width: '100%',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.04) !important' },
            }}
            onClick={() => onCandidateProfileOpen?.(info.row.original.candidate.id!)}
          >
            {info.getValue()}
          </Button>
        ),
        header: () => <div style={{ textAlign: 'center' }}>Candidate</div>,
      }),
      columnHelper.accessor(row => row.stage, {
        id: 'stage',
        cell: info => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <StageChip stage={info.getValue()} />
          </div>
        ),
        header: () => <div style={{ textAlign: 'center' }}>Stage</div>,
        filterFn: 'equalsString',
      }),
    ];
    if (candidateOutreachStats) {
      colConfigs.push(
        columnHelper.accessor('stat.totalOpenCount', {
          cell: info => (
            <div style={{ textAlign: 'center' }}>
              {info.getValue() !== undefined ? info.getValue() : 0}
            </div>
          ),
          header: () => <div style={{ textAlign: 'center' }}>Opens</div>,
        }),
        columnHelper.accessor('stat.totalClickCount', {
          cell: info => (
            <div style={{ textAlign: 'center' }}>
              {info.getValue() !== undefined ? info.getValue() : 0}
            </div>
          ),
          header: () => <div style={{ textAlign: 'center' }}>Clicks</div>,
        })
      );
    }
    if (candidateOutreachThreads) {
      colConfigs.push(
        columnHelper.accessor(row => getReplied(row.candidateOutreachThread), {
          id: 'replied',
          cell: info => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <RepliedChip replied={info.getValue()} />
            </div>
          ),
          header: () => <div style={{ textAlign: 'center' }}>Replied</div>,
          filterFn: 'equalsString',
        })
      );
    }
    if (outreach) {
      colConfigs.push(
        columnHelper.accessor(row => getStatus(row.stage as PipelineStageEnum, row.candidate), {
          id: 'status',
          cell: info => (
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <StatusChip status={info.getValue()} />
            </div>
          ),
          header: 'Status',
        })
      );
    }
    if (tableConfig?.onCandidateSelection) {
      colConfigs.unshift(
        columnHelper.accessor(() => {}, {
          id: 'select',
          cell: ({ row }) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Checkbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          ),
          header: () => <div style={{ textAlign: 'center' }}>Select</div>,
        })
      );
    }
    return colConfigs;
  }, [outreach, candidateOutreachStats, candidateOutreachThreads]);

  const filterConfigs: FilterConfig[] = [
    {
      id: 'stage',
      label: 'Stage',
      FilterComponent: StageFilter,
      getFilterValue: (value: string | PipelineStageEnum | undefined) => {
        return PIPELINE_STAGE_TO_USER_STRINGS[value as PipelineStageEnum] || value || '';
      },
    },
  ];

  if (outreach) {
    filterConfigs.push({
      id: 'status',
      label: 'Status',
      FilterComponent: StatusFilter,
    });
  }

  if (candidateOutreachThreads) {
    filterConfigs.push({
      id: 'replied',
      label: 'Replied',
      FilterComponent: RepliedFilter,
    });
  }

  const data = useMemo(
    () =>
      Object.values(
        getCandidateRowById(
          candidatePipeline,
          candidateOutreachStats,
          candidateOutreachThreads,
          additionalCandidates
        )
      ),
    [
      outreach,
      candidatePipeline,
      candidateOutreachStats,
      candidateOutreachThreads,
      additionalCandidates,
    ]
  );

  const handleRowSelection = (selectedRowsOb: Record<string, boolean>) => {
    const selectedRows = Object.keys(selectedRowsOb).filter(id => selectedRowsOb[id]);
    const selectedCandidateIds = [];
    for (const rowIndex of selectedRows) {
      const { candidate } = data[parseInt(rowIndex)];
      selectedCandidateIds.push(candidate.id!);
    }
    setRowSelection(selectedRowsOb);
    tableConfig?.onCandidateSelection?.(selectedCandidateIds);
    return;
  };

  useEffect(() => {
    if (tableConfig?.onCandidateSelection) {
      const selectedRows = Object.keys(rowSelection).filter(id => rowSelection[id]);
      const selectedCandidateIds = [];
      for (const rowIndex of selectedRows) {
        const { candidate } = data[parseInt(rowIndex)];
        selectedCandidateIds.push(candidate.id!);
      }
      tableConfig?.onCandidateSelection?.(selectedCandidateIds);
    }
  }, [rowSelection]);

  const tableInstance = useReactTable({
    data: data,
    columns: columnConfigs,
    state: {
      columnFilters: columnsFiltersState,
      rowSelection,
    },
    enableRowSelection: Boolean(tableConfig?.onCandidateSelection),
    onRowSelectionChange: handleRowSelection as OnChangeFn<Record<string, boolean>>,
    onColumnFiltersChange: setColumnsFiltersState,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
  });

  return (
    <>
      <div className={styles.filtersContainer}>
        {columnsFiltersState.map(filter => (
          <PipelineFilterButton
            key={filter.id}
            tableInstance={tableInstance}
            filter={filter}
            filterConfigs={filterConfigs}
          />
        ))}
        {getUnsetFilters(filterConfigs, columnsFiltersState).length > 0 && (
          <PipelineCreateFilterButton
            tableInstance={tableInstance}
            columnsFiltersState={columnsFiltersState}
            filterConfigs={filterConfigs}
          />
        )}
      </div>

      <Table
        aria-label="table variants"
        variant="plain"
        color="neutral"
        sx={{
          '& th': {
            backgroundColor: '#f5f5f5',
            fontWeight: 600,
            padding: '12px 16px',
            borderBottom: '1px solid #e0e0e0',
          },
          '& td': {
            padding: '12px 16px',
            borderBottom: '1px solid #e0e0e0',
          },
          '& tr:last-child td': {
            borderBottom: 'none',
          },
          '& tr:hover': {
            backgroundColor: 'rgba(0,0,0,0.02)',
          },
          borderCollapse: 'collapse',
          width: '100%',
        }}
      >
        <thead>
          {tableInstance.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {tableInstance.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
