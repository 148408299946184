import { CandidateItem } from 'api-types';
import { Divider, Typography, Card, CardContent, Chip, Stack } from '@mui/joy';
import { Briefcase, GraduationCap, Star } from 'lucide-react';
import styles from '../../candidate.module.css';
import { Section } from './Section';
import { humanizeDateRange, humanizeDegree } from '../../utils';

export function CandidateProfileDetails({ candidate }: { candidate: CandidateItem }) {
  return (
    <div className={styles.profileContainer}>
      {candidate.summary && (
        <Card variant="outlined" className={styles.section}>
          <CardContent>
            <Typography level="title-md" fontWeight="bold" gutterBottom>
              About
            </Typography>
            <Typography level="body-md" textColor="text.secondary">
              {candidate.summary}
            </Typography>
          </CardContent>
        </Card>
      )}

      {candidate.experiences?.length && (
        <>
          <Divider />
          <Typography
            level="title-md"
            fontWeight="bold"
            mt={2}
            mb={1}
            display="flex"
            alignItems="center"
          >
            <Briefcase size={20} style={{ marginRight: 8 }} /> Experience
          </Typography>
          {candidate.experiences.map((ex, index) => (
            <Card key={index} variant="soft" className={styles.card}>
              <CardContent>
                <Typography level="title-md" fontWeight="lg">
                  {ex.position || ''}
                </Typography>
                <Typography level="body-sm" textColor="text.tertiary">
                  {ex.company} • {ex.startDate ? humanizeDateRange(ex.startDate, ex.endDate) : ''}
                </Typography>
                <Typography level="body-sm" mt={1}>
                  {ex.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </>
      )}

      {candidate.education?.length && (
        <>
          <Divider />
          <Typography
            level="title-md"
            fontWeight="bold"
            mt={2}
            mb={1}
            display="flex"
            alignItems="center"
          >
            <GraduationCap size={20} style={{ marginRight: 8 }} /> Education
          </Typography>
          {candidate.education.map((ed, index) => (
            <Card key={index} variant="soft" className={styles.card}>
              <CardContent>
                <Typography level="title-md" fontWeight="lg">
                  {ed.degree ? humanizeDegree(ed.degree, ed.fieldOfStudy) : ''}
                </Typography>
                <Typography level="body-sm" textColor="text.tertiary">
                  {ed.institute} • {ed.startDate ? humanizeDateRange(ed.startDate, ed.endDate) : ''}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </>
      )}

      {candidate.skills && (
        <>
          <Divider />
          <Typography level="title-md" fontWeight="bold" mt={2} mb={1}>
            <Star size={20} style={{ marginRight: 8 }} /> Skills
          </Typography>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            {candidate.skills.split(',').map((skill, index) => (
              <Chip key={index} variant="soft">
                {skill.trim()}
              </Chip>
            ))}
          </Stack>
        </>
      )}
    </div>
  );
}
