import { Box, Button, IconButton, Tab, TabPanel, Tabs, Typography } from '@mui/joy';
import { Pipeline } from 'pages/outreach/pipeline/Pipeline';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppStore } from 'store/appStore';
import ShowAllCandidatesResult from 'pages/interview/interview-result/ShowAllCandidatesResult';
import { TabList } from 'components/Tabs/TabList';
import { fetchInterviewConfigByProject } from 'api/api';
import { InterviewConfig } from 'api-types';
import { EDIT_PATH } from '../app/constants';
import { SpinnerLoader } from 'components/Loader/SpinnerLoader';
import { useSnackbar } from 'components/Snackbar/SnackbarContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

enum TabEnum {
  Pipeline = 'pipeline',
  InterviewResults = 'interview-results',
}

export function InterviewPage() {
  const [selectedTab, setSelectedTab] = useState(TabEnum.Pipeline);
  const { interviewId } = useParams();
  const navigate = useNavigate();
  const setActiveProjectId = useAppStore(state => state.setActiveProjectId);
  const fetchProject = useAppStore(state => state.fetchProject);
  const resetInterviewConfig = useAppStore(state => state.resetInterviewConfig);
  const resetProject = useAppStore(state => state.resetProject);
  const setActiveInterviewId = useAppStore(state => state.setActiveInterviewId);
  const fetchInterviewConfig = useAppStore(state => state.fetchInterviewConfig);
  const interviewConfig = useAppStore(state => state.interviewConfig);
  const [loading, setLoading] = useState(false);
  const { showErrorSnackbar } = useSnackbar();

  const fetchInterviewAsync = async () => {
    try {
      setLoading(true);
      await fetchInterviewConfig();
    } catch (error) {
      showErrorSnackbar('Failed to fetch interview');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchProjectAsync() {
      if (interviewConfig?.projectId) {
        setActiveProjectId(interviewConfig?.projectId);
        await fetchProject();
      }
    }
    fetchProjectAsync();
  }, [interviewConfig?.projectId]);

  useEffect(() => {
    if (interviewId) {
      setActiveInterviewId(interviewId);
      fetchInterviewAsync();
    }
  }, [interviewId]);

  useEffect(() => {
    return () => {
      resetInterviewConfig();
      resetProject();
      setActiveProjectId(undefined);
    };
  }, []);

  const handleInterviewConfigUpdate = async () => {
    navigate(`${EDIT_PATH}/${interviewConfig?.id}`);
  };

  return (
    <div>
      {loading && <SpinnerLoader />}
      {!loading && (
        <>
          <IconButton
            variant="plain"
            color="neutral"
            size="sm"
            onClick={() => navigate('/')}
            style={{ marginBottom: '10px' }}
          >
            <ArrowBackIcon fontSize="small" />
            Back
          </IconButton>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography level="title-lg">{interviewConfig?.metaInfo?.name}</Typography>
            <Button
              variant="outlined"
              color="neutral"
              onClick={handleInterviewConfigUpdate}
              className="text-gray-700 font-medium"
            >
              Edit Project
            </Button>
          </Box>
          <Tabs
            value={selectedTab}
            sx={{ backgroundColor: 'transparent' }}
            onChange={(e, val: string | number | null) => setSelectedTab(val as typeof selectedTab)}
          >
            <TabList>
              <Tab value={TabEnum.Pipeline}>Pipeline</Tab>
              <Tab value={TabEnum.InterviewResults}>Interview Results</Tab>
            </TabList>
            <TabPanel value={TabEnum.Pipeline}>
              <Pipeline />
            </TabPanel>

            <TabPanel value={TabEnum.InterviewResults}>
              <ShowAllCandidatesResult />
            </TabPanel>
          </Tabs>
        </>
      )}
    </div>
  );
}
