import React, { useEffect, useState } from 'react';
import { useAppStore } from 'store/appStore';
import { useParams, useNavigate } from 'react-router-dom';
import { Card } from '../../../shadcn/components/ui/card';
import { Button } from '../../../shadcn/components/ui/button';
import {
  ArrowLeft,
  User,
  Mail,
  FileText,
  ChevronDown,
  ChevronUp,
  MessageCircle,
} from 'lucide-react';
import { InterviewAssessment } from 'api-types';
import {
  formatTimestamp,
  getScoreColor,
  getOverallScoreColor,
  getScoreEmoji,
  getHireableInfo,
} from './utils';

const CandidateFullReport = () => {
  const { assessmentId } = useParams();
  const fetchInterviewAssessment = useAppStore(state => state.fetchInterviewAssessment);
  const fetchInterviewVideoUrl = useAppStore(state => state.fetchInterviewVideoUrl);
  const navigate = useNavigate();

  const [assessment, setAssessment] = useState<InterviewAssessment | null>(null);
  const [loading, setLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoLoading, setVideoLoading] = useState(false);
  const [expandedSections, setExpandedSections] = useState({
    overallFeedback: true,
    criteriaFeedback: true,
    transcript: false,
    video: false,
  });

  const toggleSection = (section: keyof typeof expandedSections) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  useEffect(() => {
    const loadAssessment = async () => {
      if (!assessmentId) return;

      setLoading(true);
      try {
        const response = await fetchInterviewAssessment({ assessmentId });
        setAssessment(response);
      } catch (error) {
        console.error('Failed to load assessment:', error);
      } finally {
        setLoading(false);
      }
    };

    loadAssessment();
  }, [assessmentId, fetchInterviewAssessment]);

  useEffect(() => {
    const loadVideoUrl = async () => {
      if (!assessmentId || !expandedSections.video) return;
      
      setVideoLoading(true);
      try {
        const response = await fetchInterviewVideoUrl({ assessmentId });
        setVideoUrl(response.videoUrl);
      } catch (error) {
        console.error('Failed to load video URL');
      } finally {
        setVideoLoading(false);
      }
    };

    loadVideoUrl();
  }, [assessmentId, expandedSections.video, fetchInterviewVideoUrl]);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-700"></div>
      </div>
    );
  }

  if (!assessment) {
    return (
      <Card className="shadow-sm border border-gray-200 p-6">
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-2">Assessment Not Found</h2>
          <Button onClick={handleGoBack} className="mt-4">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Go Back
          </Button>
        </div>
      </Card>
    );
  }

  const hireableInfo = getHireableInfo(assessment.evaluationContent?.hireable || 'No Decision');

  return (
    <div className="max-w-7xl mx-auto pb-16">
      <div className="mb-6">
        <Button variant="outline" onClick={handleGoBack} className="mb-4">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Results
        </Button>

        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Candidate Assessment Report</h1>
          </div>

          <div
            className={`px-4 py-2 rounded-full border ${hireableInfo.className} font-semibold text-sm inline-flex items-center`}
          >
            {hireableInfo.icon}
            <span className="ml-1">{hireableInfo.text}</span>
          </div>
        </div>
      </div>

      <div className="bg-white border border-gray-200 rounded-lg p-6 mb-6 shadow-sm w-full">
        <div className="flex items-center mb-4">
          <div className="h-16 w-16 rounded-full bg-gray-200 flex items-center justify-center text-gray-500">
            <User className="h-8 w-8" />
          </div>
          <div className="ml-4">
            <h2 className="text-xl font-semibold text-gray-900">
              {assessment.interviewee?.firstName || ''} {assessment.interviewee?.lastName || ''}
            </h2>
            <div className="flex items-center text-gray-600 mt-1">
              <Mail className="h-4 w-4 mr-2" />
              {assessment.interviewee?.email || 'No email provided'}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 pt-4 border-t border-gray-100">
          <div>
            <div className="text-sm font-medium text-gray-500">Role</div>
            <div className="mt-1 font-medium">
              {assessment.interviewConfig?.role || 'Not specified'}
            </div>
          </div>
          <div>
            <div className="text-sm font-medium text-gray-500">Interview Focus</div>
            <div className="mt-1">{assessment.interviewConfig?.focus || 'Not specified'}</div>
          </div>
        </div>
      </div>

      <div className="bg-white border border-gray-200 rounded-lg p-6 mb-6 shadow-sm w-full">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-gray-900">Overall Score</h2>
          <div
            className={`text-3xl font-bold rounded-full h-14 w-14 flex items-center justify-center ${getOverallScoreColor(
              assessment.evaluationContent?.overallScore ?? 0
            )}`}
          >
            {assessment.evaluationContent?.overallScore ?? 0}
          </div>
        </div>

        <div className="border-t border-gray-100 pt-4">
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => toggleSection('overallFeedback')}
          >
            <h3 className="text-md font-medium text-gray-900">Overall Feedback</h3>
            {expandedSections.overallFeedback ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </div>

          {expandedSections.overallFeedback && (
            <div className="mt-3 text-gray-700 bg-gray-50 p-4 rounded-md">
              {assessment.evaluationContent?.overallFeedback?.length ? (
                assessment.evaluationContent.overallFeedback.map((feedback, index) => (
                  <p key={index} className="mb-2 last:mb-0 break-words">
                    {feedback}
                  </p>
                ))
              ) : (
                <p>No feedback provided</p>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="bg-white border border-gray-200 rounded-lg p-6 mb-6 shadow-sm w-full">
        <div
          className="flex items-center justify-between mb-4 cursor-pointer"
          onClick={() => toggleSection('criteriaFeedback')}
        >
          <h2 className="text-lg font-semibold text-gray-900">Assessment Criteria</h2>
          {expandedSections.criteriaFeedback ? (
            <ChevronUp className="h-5 w-5 text-gray-500" />
          ) : (
            <ChevronDown className="h-5 w-5 text-gray-500" />
          )}
        </div>

        {expandedSections.criteriaFeedback && (
          <div className="border-t border-gray-100 pt-4">
            {assessment.evaluationContent?.criteriaFeedback?.length ? (
              assessment.evaluationContent.criteriaFeedback.map((criterion, index) => {
                const score = criterion.score ?? 0;
                return (
                  <div
                    key={index}
                    className="mb-4 last:mb-0 pb-4 last:pb-0 border-b last:border-b-0 border-gray-100"
                  >
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center">
                        {getScoreEmoji(score)}
                        <h3 className="ml-2 font-medium text-gray-900">
                          {criterion.criteria || 'N/A'}
                        </h3>
                      </div>
                      <div className={`font-semibold ${getScoreColor(score)}`}>
                        Score: {score}/5
                      </div>
                    </div>
                    <div className="ml-7 text-gray-700 break-words">
                      {criterion.feedback || 'No feedback provided'}
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-gray-500 italic">No criteria feedback available</p>
            )}
          </div>
        )}
      </div>

      <div className="bg-white border border-gray-200 rounded-lg p-6 mb-6 shadow-sm w-full">
        <div
          className="flex items-center justify-between mb-4 cursor-pointer"
          onClick={() => toggleSection('video')}
        >
          <h2 className="text-lg font-semibold text-gray-900">Interview Recording</h2>
          {expandedSections.video ? (
            <ChevronUp className="h-5 w-5 text-gray-500" />
          ) : (
            <ChevronDown className="h-5 w-5 text-gray-500" />
          )}
        </div>

        {expandedSections.video && (
          <div className="border-t border-gray-100 pt-4">
            {videoLoading ? (
              <div className="flex justify-center items-center py-8">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-700"></div>
              </div>
            ) : videoUrl ? (
              <div className="aspect-video w-full">
                <video 
                  src={videoUrl} 
                  controls 
                  className="w-full h-full object-cover rounded-md"
                >
                  Your browser does not support the video.
                </video>
              </div>
            ) : (
              <div className="text-center text-gray-500 py-8">
                <FileText className="h-12 w-12 mx-auto mb-3 text-gray-400" />
                <p>No video recording available for this interview</p>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="bg-white border border-gray-200 rounded-lg p-6 shadow-sm w-full">
        <div
          className="flex items-center justify-between mb-4 cursor-pointer"
          onClick={() => toggleSection('transcript')}
        >
          <h2 className="text-lg font-semibold text-gray-900">Interview Transcript</h2>
          {expandedSections.transcript ? (
            <ChevronUp className="h-5 w-5 text-gray-500" />
          ) : (
            <ChevronDown className="h-5 w-5 text-gray-500" />
          )}
        </div>

        {expandedSections.transcript && (
          <div className="border-t border-gray-100 pt-4">
            {assessment.transcriptContent && assessment.transcriptContent.length > 0 ? (
              assessment.transcriptContent.map((entry, index) => (
                <div key={index} className="mb-6 last:mb-0">
                  {entry.Agent && (
                    <div className="mb-2">
                      <div className="flex items-start">
                        <div className="h-8 w-8 rounded-full bg-black flex items-center justify-center text-white mr-3 mt-1">
                          <MessageCircle className="h-4 w-4" />
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-between items-center mb-1">
                            <div className="font-medium text-gray-900">Interviewer</div>
                            <div className="text-xs text-gray-500">
                              {formatTimestamp(entry.agentTimestamp)}
                            </div>
                          </div>
                          <div className="p-3 bg-gray-100 rounded-lg text-gray-700 break-words">
                            {entry.Agent}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {entry.User && (
                    <div className="mb-4">
                      <div className="flex items-start">
                        <div className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center text-gray-600 mr-3 mt-1">
                          <User className="h-4 w-4" />
                        </div>
                        <div className="flex-1">
                          <div className="flex justify-between items-center mb-1">
                            <div className="font-medium text-gray-900">Candidate</div>
                            <div className="text-xs text-gray-500">
                              {formatTimestamp(entry.userTimestamp)}
                            </div>
                          </div>
                          <div className="p-3 bg-gray-50 rounded-lg text-gray-700 break-words">
                            {entry.User}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 py-8">
                <FileText className="h-12 w-12 mx-auto mb-3 text-gray-400" />
                <p>No transcript available for this interview</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CandidateFullReport;
