import {
  BatchUploadCandidatesRequest,
  CreateProjectRequest,
  CreateProjectResponse,
  GenerateOutreachMessageRequest,
  GenerateOutreachMessageResponse,
  GetBootstrapInfoResponse,
  GetCandidatePipelineRequest,
  GetCandidatePipelineResponse,
  GetOutreachRequest,
  GetOutreachResponse,
  GetProjectCriteriaFormRequest,
  GetProjectCriteriaFormResponse,
  GetProjectRequest,
  GetProjectResponse,
  SearchCandidatesRequest,
  SearchCandidatesResponse,
  SendProjectMessageRequest,
  SendProjectMessageResponse,
  UpdateProjectCriteriaRequest,
  UpdateProjectCriteriaResponse,
  BatchUpsertCandidateStageRequest,
  BatchUpsertCandidateStageResponse,
  UpsertOutreachRequest,
  UpsertOutreachResponse,
  BulkSendOutreachRequest,
  BulkSendOutreachResponse,
  BatchGetCandidateOutreachStatsResponse,
  BatchGetCandidateOutreachStatsRequest,
  GetAllOutreachMessagesRequest,
  GetAllOutreachMessagesResponse,
  SendEmailRequest,
  SendEmailResponse,
  HasAdminAccessResponse,
  GetAllUsersResponse,
  GetAllProjectsForUserRequest,
  GetAllProjectsForUserResponse,
  GetCandidatesByStageRequest,
  GetCandidatesByStageResponse,
  UpdateProjectRequest,
  UpdateProjectResponse,
  GetAllOutreachPhoneConversationsRequest,
  GetAllOutreachPhoneConversationsResponse,
  UpsertOutreachPhoneConversationRequest,
  UpsertOutreachPhoneConversationResponse,
  BatchGetCandidateOutreachThreadRequest,
  BatchGetCandidateOutreachThreadResponse,
  ClearProjectMessagesRequest,
  GetLocationsByPrefixResponse,
  GetLocationsByPrefixRequest,
  DeleteProjectRequest,
  DeleteProjectResponse,
  GetFilterDataByPrefixRequest,
  GetFilterDataByPrefixResponse,
  GetCompaniesDataByPrefixResponse,
  GenerateCriteriaRequest,
  GenerateCriteriaResponse,
  UpdateAutopilotConfigurationRequest,
  UpdateAutopilotConfigurationResponse,
  GetAutopilotConfigurationRequest,
  GetAutopilotConfigurationResponse,
  FetchInterviewConfigRequest,
  FetchInterviewConfigResponse,
  UpsertInterviewConfigRequest,
  DeleteInterviewConfigRequest,
  DeleteInterviewConfigResponse,
  UpsertInterviewConfigResponse,
  FetchAllInterviewsRequest,
  FetchAllInterviewsResponse,
  ConnectionDetailsRequest,
  ConnectionDetailsResponse,
  FetchInterviewLibraryDataResponse,
  FetchInterviewLibraryDataRequest,
  FetchInterviewAssessmentRequest,
  FetchInterviewAssessmentResponse,
  FetchInterviewAssessmentsByInterviewIdRequest,
  FetchInterviewAssessmentsByInterviewIdResponse,
  FetchScreeningCriteriaAndQuestionsRequest,
  FetchScreeningCriteriaAndQuestionsResponse,
  FetchInterviewConfigByProjectRequest,
  FetchInterviewConfigByProjectResponse,
  FetchInterviewVideoUrlRequest,
  FetchInterviewVideoUrlResponse,
} from 'api-types';
import { EventSourceCallbacks, post, postStreamRequest } from './client';

export const getBootstrapInfo = async (): Promise<GetBootstrapInfoResponse> => {
  return await post('/getBootstrapInfo');
};

export const getProject = async (req: GetProjectRequest): Promise<GetProjectResponse> => {
  return await post('/project/getProject', req);
};

export const createProject = async (req: CreateProjectRequest): Promise<CreateProjectResponse> => {
  return await post('/project/createProject', req);
};

export const deleteProject = async (req: DeleteProjectRequest): Promise<DeleteProjectResponse> => {
  return await post('/project/deleteProject', req);
};

export const updateProject = async (req: UpdateProjectRequest): Promise<UpdateProjectResponse> => {
  return await post('/project/updateProject', req);
};

export const sendProjectMessage = (
  req: SendProjectMessageRequest,
  callbacks: EventSourceCallbacks<SendProjectMessageResponse>
) => {
  postStreamRequest('/assistant/sendProjectMessage', req, callbacks);
};

export const getProjectCriteria = async (
  req: GetProjectCriteriaFormRequest
): Promise<GetProjectCriteriaFormResponse> => {
  return await post('/criteria/getCriteria', req);
};

export const updateProjectCriteria = async (
  req: UpdateProjectCriteriaRequest
): Promise<UpdateProjectCriteriaResponse> => {
  return await post('/criteria/updateCriteria', req);
};

export const getOutreach = async (req: GetOutreachRequest): Promise<GetOutreachResponse> => {
  return await post('/outreach/getOutreach', req);
};

export const upsertOutreach = async (
  req: UpsertOutreachRequest
): Promise<UpsertOutreachResponse> => {
  return await post('/outreach/upsertOutreach', req);
};

export const generateOutreachMessage = async (
  req: GenerateOutreachMessageRequest,
  callbacks: EventSourceCallbacks<GenerateOutreachMessageResponse>
) => {
  postStreamRequest('/outreach/generateMessage', req, callbacks);
};

export const sendEmail = async (req: SendEmailRequest): Promise<SendEmailResponse> => {
  return await post('/outreach/sendEmail', req);
};

export const batchUploadCandidates = async (req: BatchUploadCandidatesRequest) => {
  return await post('/candidateUpload/batch', req);
};

export const searchCandidatesByProject = async (
  req: SearchCandidatesRequest
): Promise<SearchCandidatesResponse> => {
  return await post('/candidate/search', req);
};

export const batchAddCandidatesToPipeline = async (
  req: BatchUpsertCandidateStageRequest
): Promise<BatchUpsertCandidateStageResponse> => {
  return await post('/pipeline/batchAddToPipeline', req);
};

export const batchUpdateCandidateStages = async (
  req: BatchUpsertCandidateStageRequest
): Promise<BatchUpsertCandidateStageResponse> => {
  return await post('/pipeline/batchUpdateStage', req);
};

export const bulkSendOutreach = async (
  req: BulkSendOutreachRequest
): Promise<BulkSendOutreachResponse> => {
  return post('/outreachMessage/bulk/send', req);
};

export const getCandidatePipeline = async (
  req: GetCandidatePipelineRequest
): Promise<GetCandidatePipelineResponse> => {
  return await post('/pipeline/getPipeline', req);
};

export const getCandidatesByStage = async (
  req: GetCandidatesByStageRequest
): Promise<GetCandidatesByStageResponse> => {
  return await post('/pipeline/getCandidate', req);
};

export const batchGetCandidateOutreachStats = async (
  req: BatchGetCandidateOutreachStatsRequest
): Promise<BatchGetCandidateOutreachStatsResponse> => {
  return await post('/outreachMessage/batchGetCandidateOutreachStats', req);
};

export const getAllOutreachMessages = async (
  req: GetAllOutreachMessagesRequest
): Promise<GetAllOutreachMessagesResponse> => {
  return await post('/outreachMessage/get/all', req);
};

export const hasAdminAccess = async (): Promise<HasAdminAccessResponse> => {
  return await post('/admin/hasAdminAccess');
};

export const getAllUsers = async (): Promise<GetAllUsersResponse> => {
  return await post('/admin/getAllUsers');
};

export const getAllProjectsForUser = async (
  req: GetAllProjectsForUserRequest
): Promise<GetAllProjectsForUserResponse> => {
  return await post('/admin/getAllProjectForUser', req);
};

export const getAllOutreachPhoneConversations = async (
  req: GetAllOutreachPhoneConversationsRequest
): Promise<GetAllOutreachPhoneConversationsResponse> => {
  return await post('/outreachMessage/getAllPhoneConversations', req);
};

export const upsertOutreachPhoneConversation = async (
  req: UpsertOutreachPhoneConversationRequest
): Promise<UpsertOutreachPhoneConversationResponse> => {
  return await post('/outreachMessage/upsertPhoneConversation', req);
};

export const batchGetCandidateOutreachThreads = async (
  req: BatchGetCandidateOutreachThreadRequest
): Promise<BatchGetCandidateOutreachThreadResponse> => {
  return await post('/outreachMessage/batchGetOutreachThread', req);
};

export const clearProjectMessages = async (req: ClearProjectMessagesRequest) => {
  return await post('/assistant/clearProjectMessages', req);
};

export const fetchLocations = async (
  req: GetLocationsByPrefixRequest
): Promise<GetLocationsByPrefixResponse> => {
  return await post('/criteria/fetchLocations', req);
};

export const fetchCompanies = async (
  req: GetFilterDataByPrefixRequest
): Promise<GetCompaniesDataByPrefixResponse> => {
  return await post('/criteria/fetchCompanies', req);
};

export const generateProjectCriteria = async (
  req: GenerateCriteriaRequest
): Promise<GenerateCriteriaResponse> => {
  return await post('/criteria/generateCriteria', req);
};

export const fetchSchools = async (
  req: GetFilterDataByPrefixRequest
): Promise<GetFilterDataByPrefixResponse> => {
  return await post('/criteria/fetchSchools', req);
};

export const fetchJobTitles = async (
  req: GetFilterDataByPrefixRequest
): Promise<GetFilterDataByPrefixResponse> => {
  return await post('/criteria/fetchJobTitles', req);
};

export const fetchSkills = async (
  req: GetFilterDataByPrefixRequest
): Promise<GetFilterDataByPrefixResponse> => {
  return await post('/criteria/fetchSkills', req);
};

export const updateAutopilotConfiguration = async (
  req: UpdateAutopilotConfigurationRequest
): Promise<UpdateAutopilotConfigurationResponse> => {
  return await post('/assistant/updateAutopilotConfiguration', req);
};

export const getAutopilotConfiguration = async (
  req: GetAutopilotConfigurationRequest
): Promise<GetAutopilotConfigurationResponse> => {
  return await post('/assistant/getAutopilotConfiguration', req);
};

export const fetchInterviewConfig = async (
  req: FetchInterviewConfigRequest
): Promise<FetchInterviewConfigResponse> => {
  return await post('/interview/fetchInterviewConfig', req);
};

export const createInterviewConfig = async (
  req: UpsertInterviewConfigRequest
): Promise<UpsertInterviewConfigResponse> => {
  return await post('/interview/createInterviewConfig', req);
};

export const updateInterviewConfig = async (
  req: UpsertInterviewConfigRequest
): Promise<UpsertInterviewConfigResponse> => {
  return await post('/interview/updateInterviewConfig', req);
};

export const deleteInterviewConfig = async (
  req: DeleteInterviewConfigRequest
): Promise<DeleteInterviewConfigResponse> => {
  return await post('/interview/deleteInterviewConfig', req);
};

export const fetchAllInterviews = async (
  req: FetchAllInterviewsRequest
): Promise<FetchAllInterviewsResponse> => {
  return await post('/interview/fetchAllInterviewConfigs', req);
};

export const getConnectionDetails = async (
  req: ConnectionDetailsRequest
): Promise<ConnectionDetailsResponse> => {
  return await post('/interview/connectionDetails', req);
};

export const fetchInterviewLibraryData = async (
  req: FetchInterviewLibraryDataRequest
): Promise<FetchInterviewLibraryDataResponse> => {
  return await post('/interview/fetchInterviewLibraryData', req);
};

export const fetchInterviewAssessment = async (
  req: FetchInterviewAssessmentRequest
): Promise<FetchInterviewAssessmentResponse> => {
  return await post('/interview/fetchInterviewAssessment', req);
};

export const fetchInterviewAssessmentsByInterviewId = async (
  req: FetchInterviewAssessmentsByInterviewIdRequest
): Promise<FetchInterviewAssessmentsByInterviewIdResponse> => {
  return await post('/interview/fetchInterviewAssessmentsByInterviewId', req);
};

export const fetchScreeningCriteriaAndQuestions = async (
  req: FetchScreeningCriteriaAndQuestionsRequest
): Promise<FetchScreeningCriteriaAndQuestionsResponse> => {
  return await post('/interview/fetchScreeningCriteriaAndQuestions', req);
}
  
export const fetchInterviewConfigByProject = async (
  req: FetchInterviewConfigByProjectRequest
): Promise<FetchInterviewConfigByProjectResponse> => {
  return await post('/interview/fetchInterviewConfigByProjectId', req);
};

export const fetchInterviewVideoUrl = async (
  req: FetchInterviewVideoUrlRequest
): Promise<FetchInterviewVideoUrlResponse> => {
  return await post('/interview/fetchInterviewVideoUrl', req);
};
