import React, { useState, Suspense } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from 'shadcn/components/ui/card';
import { Button } from 'shadcn/components/ui/button';
import { generateRoomId } from './room-setup/lib/utils';
import { useNavigate } from 'react-router-dom';
import { ROOM_PATH } from '../constants';

const LiveInterviewHome: React.FC = () => {
  const navigate = useNavigate();
  const startMeeting = () => {
    const roomName = generateRoomId();
    navigate(`${ROOM_PATH}/${roomName}`);
  };

  return (
    <div className="w-full md:p-6 lg:p-8 bg-white flex items-start justify-center">
      <Card className="w-full max-w-4xl shadow-lg bg-white">
        <CardHeader className="pb-4 md:pb-6">
          <CardTitle className="text-2xl md:text-3xl font-bold text-center text-black underline">
            Welcome to the Interview Process
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <section>
            <h2 className="text-lg md:text-xl font-semibold mb-2 text-black">
              Pre-Interview Guidelines
            </h2>
            <ul className="space-y-1.5 text-gray-700 list-disc pl-4">
              <li>
                Choose a quiet, well-lit environment free from distractions and background noises.
              </li>
              <li>
                Ensure a stable internet connection and test your microphone and camera beforehand.
              </li>
              <li>
                Close unnecessary applications and browser tabs, keep the interview window active at
                all times.
              </li>
              <li>Avoid switching tabs or minimizing the window during the interview.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-lg md:text-xl font-semibold mb-2 text-black">
              Interview Instructions
            </h2>
            <ul className="space-y-1.5 text-gray-700 list-disc pl-4">
              <li>Click the "Start Interview" button when you are ready to begin.</li>
              <li>
                Answer each question thoughtfully and concisely, ensuring your responses are clear
                and complete.
              </li>
              <li>Maintain a professional demeanor throughout the entire interview process.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-lg md:text-xl font-semibold mb-2 text-red-700">
              Cheating & Fair Play
            </h2>
            <ul className="space-y-1.5 text-red-700 list-disc pl-4">
              <li>
                Answer all questions in your own words, AI-generated responses or external help are
                strictly prohibited.
              </li>
              <li>
                Switching tabs, using other applications, or seeking outside assistance during the
                interview is not allowed.
              </li>
              <li>
                Any detected misconduct may result in immediate disqualification from the process.
              </li>
            </ul>
          </section>

          <div className="flex justify-center">
            <Button
              onClick={startMeeting}
              className="bg-black hover:bg-gray-800 text-white px-8 py-2.5 rounded-full transition-all duration-300 ease-in-out transform hover:scale-[1.02] active:scale-[0.98]"
            >
              Start Interview
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default LiveInterviewHome;
