import React, { useEffect, useState } from 'react';
import { useAppStore } from 'store/appStore';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from '../../../shadcn/components/ui/card';
import { Badge } from '../../../shadcn/components/ui/badge';
import { Button } from '../../../shadcn/components/ui/button';
import { Search, SlidersHorizontal, Calendar, Mail, User, FileText } from 'lucide-react';
import { InterviewAssessment } from 'api-types';
import { CandidateResult } from './types';
import { getHireableInfo } from './utils';

const ShowCandidatesResult = () => {
  const fetchInterviewAssessmentsByInterviewId = useAppStore(
    state => state.fetchInterviewAssessmentsByInterviewId
  );
  const { interviewId } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState<CandidateResult[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!interviewId) return;

      setLoading(true);
      try {
        const assessments = await fetchInterviewAssessmentsByInterviewId({ interviewId });

        const formattedData = assessments.map((assessment: InterviewAssessment) => {
          const { interviewee, evaluationContent } = assessment;
          const candidateName = [interviewee.firstName, interviewee.lastName]
            .filter(Boolean)
            .join(' ');

          return {
            id: assessment.id,
            candidateName: candidateName || 'N/A',
            email: interviewee.email || 'N/A',
            interviewDate: new Date(assessment.createdAt).toLocaleDateString(),
            score: evaluationContent?.overallScore ?? 0,
            hireable: evaluationContent?.hireable ?? 'No Hire',
          };
        });

        setData(formattedData);
      } catch (error) {
        console.error('Error fetching candidate results:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchInterviewAssessmentsByInterviewId, interviewId]);

  const handleViewReport = (assessmentId: string) => {
    navigate(`/results/candidate/${assessmentId}`);
  };

  return (
    <Card className="shadow-sm border border-gray-200 overflow-hidden">
      <CardHeader className="bg-white border-b border-gray-200 p-6">
        <div className="flex items-center justify-between">
          <div>
            <CardTitle className="text-2xl font-bold flex items-center text-gray-900">
              <User className="mr-2 text-gray-700" />
              Interview Candidates Results
            </CardTitle>
            <p className="mt-1 text-gray-500">{data.length} candidate interviewed</p>
          </div>
          <div className="flex gap-1">
            <div className="relative w-64">
              <Search
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={16}
              />
              <input
                type="text"
                placeholder="Search candidates..."
                className="pl-10 pr-4 py-2 rounded-md border border-gray-300 w-full text-sm focus:outline-none focus:ring-1 focus:ring-gray-500"
              />
            </div>
            <Button
              variant="outline"
              className="border-gray-300 text-gray-700 hover:bg-gray-100 ml-2"
            >
              <SlidersHorizontal className="mr-2 h-4 w-4" />
              Filter
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardContent className="p-0">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-700"></div>
          </div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-50 text-left border-b border-gray-200">
                    <th className="px-6 py-4 text-sm font-medium text-gray-700">Candidate Name</th>
                    <th className="px-6 py-4 text-sm font-medium text-gray-700">Email</th>
                    <th className="px-6 py-4 text-sm font-medium text-gray-700">Interview Date</th>
                    <th className="px-6 py-4 text-sm font-medium text-gray-700">Score</th>
                    <th className="px-6 py-4 text-sm font-medium text-gray-700">Hiring Decision</th>
                    <th className="px-6 py-4 text-sm font-medium text-gray-700">Full Report</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {data.map((candidate: CandidateResult) => {
                    const hireableInfo = getHireableInfo(candidate.hireable);

                    return (
                      <tr key={candidate.id} className="hover:bg-gray-50 transition-colors">
                        <td className="px-6 py-4">
                          <div className="flex items-center">
                            <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center text-gray-700 font-medium text-sm">
                              {candidate.candidateName
                                .split(' ')
                                .map((name: string) => name[0])
                                .join('')}
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {candidate.candidateName}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex items-center text-sm text-gray-700">
                            <Mail className="mr-2 h-4 w-4 text-gray-400" />
                            {candidate.email}
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="flex items-center text-sm text-gray-700">
                            <Calendar className="mr-2 h-4 w-4 text-gray-400" />
                            {candidate.interviewDate}
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <div
                            className={`text-base font-semibold ${
                              candidate.score >= 70
                                ? 'text-gray-900'
                                : candidate.score >= 40
                                  ? 'text-gray-700'
                                  : 'text-gray-500'
                            }`}
                          >
                            {candidate.score}%
                          </div>
                        </td>
                        <td className="px-6 py-4">
                          <Badge
                            className={`px-3 py-1 font-medium text-sm rounded-full border ${hireableInfo.className} hover:bg-transparent`}
                          >
                            {hireableInfo.icon}
                            {hireableInfo.text}
                          </Badge>
                        </td>
                        <td className="px-6 py-4">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => handleViewReport(candidate.id)}
                            className="text-gray-700 border-gray-300 hover:bg-gray-50 flex items-center space-x-1"
                          >
                            <FileText className="h-4 w-4 mr-1" />
                            <span>View Full Report</span>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ShowCandidatesResult;
