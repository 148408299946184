import { PipelineStageEnum } from 'api-types';
import { Chip } from '../../../../components/Chip';
import { PIPELINE_STAGE_TO_USER_STRINGS } from '../constants';
import { capitalCase } from 'change-case';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export function StageChip({ stage }: { stage: PipelineStageEnum | string | undefined }) {
  const getStageColor = (stage?: PipelineStageEnum | string) => {
    switch (stage) {
      case PipelineStageEnum.Shortlist:
        return 'gray';
      case PipelineStageEnum.OutreachInitialized:
        return 'blue';
      case PipelineStageEnum.OutreachCompleted:
        return 'yellow';
      case PipelineStageEnum.AIInterviewCompleted:
        return 'green';
      default:
        return undefined;
    }
  };

  const getChipColorType = (stage?: PipelineStageEnum | string) => {
    switch (stage) {
      case PipelineStageEnum.Shortlist:
        return 'neutral';
      case PipelineStageEnum.OutreachInitialized:
        return 'primary';
      case PipelineStageEnum.OutreachCompleted:
        return 'success';
      case PipelineStageEnum.AIInterviewCompleted:
        return 'success';
      default:
        return 'neutral';
    }
  };

  return (
    <Chip color={getChipColorType(stage)} backgroundColor={getStageColor(stage)}>
      {PIPELINE_STAGE_TO_USER_STRINGS[stage as PipelineStageEnum] || stage || ''}
    </Chip>
  );
}

export enum StatusEnum {
  NotInitialized = 'NotInitialized',
  InProcess = 'InProcess',
  Finished = 'Finished',
}

export function StatusChip({ status }: { status: StatusEnum }) {
  if (status === StatusEnum.NotInitialized) {
    return <Chip backgroundColor="gray">{capitalCase(StatusEnum.NotInitialized)}</Chip>;
  }
  if (status === StatusEnum.InProcess) {
    return <Chip color="primary">{capitalCase(StatusEnum.InProcess)}</Chip>;
  }
  return (
    <Chip color="success" backgroundColor="green">
      {StatusEnum.Finished}
    </Chip>
  );
}

export enum RepliedEnum {
  NotReplied = 'NotReplied',
  Interested = 'Interested',
  No = 'No',
}

export function RepliedChip({ replied }: { replied: RepliedEnum }) {
  if (replied === RepliedEnum.NotReplied) {
    return <>-</>;
  }
  return replied === RepliedEnum.Interested ? (
    <Chip color="success" backgroundColor="green">
      {RepliedEnum.Interested}
    </Chip>
  ) : (
    <Chip color="warning">No</Chip>
  );
}

export function RatingChip({ llmScore, onClick }: { llmScore: number; onClick?: () => void }) {
  if (llmScore >= 9) {
    return (
      <Chip
        color="success"
        onClick={onClick}
        endDecorator={<InfoOutlinedIcon fontSize="inherit" />}
      >
        Strong Yes
      </Chip>
    );
  } else if (llmScore >= 8) {
    return (
      <Chip
        color="success"
        onClick={onClick}
        endDecorator={<InfoOutlinedIcon fontSize="inherit" />}
      >
        Yes
      </Chip>
    );
  } else if (llmScore >= 7) {
    return (
      <Chip
        backgroundColor="yellow"
        onClick={onClick}
        endDecorator={<InfoOutlinedIcon fontSize="inherit" />}
      >
        Weak Yes
      </Chip>
    );
  }
  return (
    <Chip
      backgroundColor="gray"
      onClick={onClick}
      endDecorator={<InfoOutlinedIcon fontSize="inherit" />}
    >
      Maybe
    </Chip>
  );
}
